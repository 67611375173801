// http://redux.js.org/docs/basics/Actions.html
import _ from 'lodash';
import constants from './constants';
import api from '../services/api';

import { cookiesSet, cookiesGet, cookiesExpire } from 'redux-cookies';

console.log("API", api);

var actions = {

	seed: () => {
		return function (dispatch) {
			const data = dispatch(cookiesGet('MIOAPIBACKEND'));
			if (data) {
				dispatch(actions.seedResult(data));
			}
		}
	},

	clearMessage: () => {
		return constants.CLEAR_MESSAGE;
	},

	seedResult: (obj) => {
		return _.merge(constants.REDUX_COOKIES_GET_SUCCESS, JSON.parse(obj));
	},

	getCultures: () => {
		return function (dispatch, getState) {
			return api.getCultures(dispatch, getState);
		}
	},

	getPools: () => {
		return function (dispatch, getState) {
			return api.getPools(dispatch, getState);
		}
	},	

	getSentences: () => {
		return function (dispatch, getState) {
			return api.getSentences(dispatch, getState);
		}
	},

	deleteSentence: (id) => {
		return function (dispatch, getState) {
			dispatch(constants.DELETE_SENTENCE);
			return api.deleteSentence(dispatch, getState, id).then(
				() => {
					dispatch(_.merge(constants.UPDATE_SEARCH));
					dispatch(actions.getSentences());

				}
			);
		}
	},

	getSentencesResult: (obj) => {
		if (obj.error) {
			return _.merge(constants.GET_SENTENCES_FAILURE, obj);
		}
		else {
			return _.merge(constants.GET_SENTENCES_SUCCESS, obj);
		}
	},

	getCulturesResult: (res) => {
		if (res.error) {
			return _.merge(constants.GET_CULTURES_FAILURE, res);
		}
		else {
			return _.merge(constants.GET_CULTURES_SUCCESS, { cultures: res });
		}
	},	

	getPoolsResult: (res) => {
		if (res.error) {
			return _.merge(constants.GET_POOLS_FAILURE, res);
		}
		else {
			return _.merge(constants.GET_POOLS_SUCCESS, { pools: res });
		}
	},	

	deleteSentencesResult: (id, response) => {
		const obj = { id: id };
		if (response.status !== 200) {
			return _.merge(constants.DELETE_SENTENCE_FAIL, obj);
		}
		else {
			return _.merge(constants.DELETE_SENTENCE_SUCCESS, obj);
		}
	},
	
	updateSentence: (obj, callback) => {
		return function (dispatch, getState) {
			dispatch(constants.UPDATE_SENTENCE);

			const updateEnglish = (cb) => {
				if (obj.enId) {
					const en = { ...obj, id: obj.enId, sentence: obj.en };
					api.updateSentence(dispatch, getState, en).then(res => cb(res, false));
				}
				else {
					const en = { id: obj.id, sentence: obj.en, lang: 'en' };
					api.addSentenceTranslation(dispatch, getState, en).then(res => cb(res, true));
				}
			};


			const updateDutch = (cb) => {
				if (obj.nlId) {
					console.log('update nl translation');
					const nl = { ...obj, id: obj.nlId, sentence: obj.nl };
					api.updateSentence(dispatch, getState, nl).then(res => cb(res, false));
					}
				else {
					console.log('add nl translation');
					const nl = { ...obj, id: obj.id, sentence: obj.nl, lang: 'nl' };
					api.addSentenceTranslation(dispatch, getState, nl).then(res => cb(res, true));
				}				
			};

			return api.updateSentence(dispatch, getState, obj).then(
				(res) => {
					if (res) {
						if (obj.en) {
							updateEnglish((res, reload) => {
								if (res && callback) callback(reload);
							});
						}
						else if (obj.nl) {
							updateDutch(res => {
								if (res && callback) callback(false);
							});						
						}
						else {
							if (callback) callback(false);
						}
					}
				}
			);
		}
	},

	updateSentenceResult: (obj) => {
		if (obj.error) {
			return constants.UPDATE_SENTENCE_FAILURE;
		}
		else {
			return _.merge(constants.UPDATE_SENTENCE_SUCCESS, obj);
		}
	},


	getToken: (passwd) => {
		return function (dispatch, getState) {
			dispatch(constants.GET_TOKEN);
			return api.getToken(dispatch, { passwd: passwd }).then(
				() => {
					const loggedIn = getState().user.loggedIn;
					if (loggedIn === true) {
						// remember our user session in a cookie
						const userJson = JSON.stringify(getState().user);
						dispatch(cookiesSet('MIOAPIBACKEND', userJson, { expires: 3600 })); // Expires in one hour
						dispatch(actions.getCultures());
						dispatch(actions.getPools());
						dispatch(actions.getSentences());
                        setTimeout(() => dispatch(actions.refreshToken()), 9*60*1000);
					}
				}
			);
		}
	},

	getTokenResult: (obj) => {
		if (obj.error) {
			return constants.GET_TOKEN_FAILURE;
		}
		else {
			return _.merge(constants.GET_TOKEN_SUCCESS, obj);
		}
	},

	refreshToken: () => {
		return function (dispatch, getState) {
			return api.refreshToken(dispatch, getState).then(
				() => {
					setTimeout(() => dispatch(actions.refreshToken()), 9*60*1000);
				}
			);		
		};
	},

	refreshTokenResult: (obj) => {
		if (obj.error) {
			return constants.GET_TOKEN_FAILURE;
		}
		else {
			return _.merge(constants.REFRESH_TOKEN_SUCCESS, obj);
		}
	},	

	updateSearch: (data) => {
		return function (dispatch, getState) {
			dispatch(_.merge(constants.UPDATE_SEARCH, data));
			dispatch(actions.getSentences());
		}
	},

	apiFailure: (error, json) => {
		const newData = {
			error: error
		};
		return _.merge(constants.API_FAILURE, newData);
	},

	logout: () => {
		return function (dispatch, getState) {
			dispatch(constants.LOGOUT);
			dispatch(cookiesExpire('MIOAPIBACKEND'));
		}
	},



}

export default actions;
