/* Redux aware component of the form */
import { connect } from 'react-redux';
import Notification from '../notification';

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.form.loading,
    message: state.form.message,
    loggedIn: state.user.loggedIn,
    userId: state.user.id,
    level: state.user.level
  }
}

const ReduxNotification = connect(
  mapStateToProps
)(Notification);

export default ReduxNotification;
