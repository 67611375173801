import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';  // NavItem, MenuItem, NavDropdown
import Notification from './containers/notification';
import {Button,Popover,Overlay} from 'react-bootstrap';
import _ from 'lodash';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import config from '../config';

class NavBar extends Component {

constructor(props) {
    super(props);
    this.state = {showingHelp:false};
    this.handleToggle = this.handleToggle.bind(this);
}

handleToggle() {
  this.setState({ showingHelp: !this.state.showingHelp });
}

render() {

    // detect click outside:
    // https://stackoverflow.com/questions/32553158/detect-click-outside-react-component#37491578
    const popoverBottom = this.state.showingHelp ? (
        <Overlay
          show={true}
          placement="bottom"
          container={this}
          target={this.refs.popoverButton}
          style={{color:'#333333', position:'fixed'}}
        >
        	<Popover id="popover-positioned-bottom" title={this.props.helpTitle || ''} style={{color:"#333333"}}>
                <div>
        		{this.props.helpText}
                </div>
        	</Popover>
        </Overlay>
    ) : '';

    const iconStyle = !this.props.loggedIn ? { display:"none" } : {};
    const helpButton = <div ref="popoverButton" style={{display:"inline-block"}}>
                            <Button style={_.merge(iconStyle, {fontSize:"18px", cursor:"pointer"})}
                                onClick={this.handleToggle} >
                                <span className="glyphicon glyphicon-question-sign"></span>
                            </Button>
                            {popoverBottom}
                    </div>;

        const tooltip = (
          <Tooltip id="tooltip">
            <strong>Terug naar introductie</strong>
          </Tooltip>
        );

        let showBackButton = false;
        if(document.location.hash) {
          if (document.location.hash.indexOf('cards') > -1) {
              showBackButton = true;
          }
        }
        const backButton = showBackButton ?
        <OverlayTrigger placement="right" overlay={tooltip}>
          <Button
            type='button'
            className="backbutton"
            onClick={() => { document.location = '#/intro'; }}>
            <span className="glyphicon glyphicon-hand-left"></span>
          </Button>
      </OverlayTrigger> : '';



    const navbarInstance = (
      <Navbar className="fadeIn animated">

          <Navbar.Brand>
            <a href="#/dashboard">myinsightout.nl - Admin ({config.env})</a>
          </Navbar.Brand>


        <div style={{right:"0px", position:"absolute", marginTop:"9px"}} >
            <Notification>
                {helpButton}
            </Notification>
        </div>
        {backButton}

      </Navbar>
    );
    return navbarInstance;
}

}

export default NavBar;
