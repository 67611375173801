//import { combineReducers } from 'redux';
import constants from './constants';
import _ from 'lodash';

const initialState = {
	user: {
		id: -1, loggedIn: false, level: -1,
		token: null, refresh_token: null
	},
	navbar: { help: { title: "Help", text: "Helptekst" } },
	listdata: undefined,
	cultures: [],
	pools: [],
	listfilter: { approved: false, gender: undefined, valueType: undefined, page: 0, categories: undefined },
	form: { loading: false, message: null },
};

function userData(state = initialState, action) {
	switch (action.type) {
		case constants.GET_CULTURES:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: true })
			});
		case constants.GET_CULTURES_SUCCESS.type:
			return Object.assign({}, state, {
				cultures: action.cultures,
				form: _.merge(state.form, { loading: false })
			});
		case constants.GET_CULTURES_FAILURE.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: action.error ? action.error : 'Kon culturen niet ophalen' })
			});
		case constants.GET_POOLS:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: true })
			});
		case constants.GET_POOLS_SUCCESS.type:
			return Object.assign({}, state, {
				pools: action.pools,
				form: _.merge(state.form, { loading: false })
			});
		case constants.GET_POOLS_FAILURE.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: action.error ? action.error : 'Kon pools niet ophalen' })
			});
		case constants.UPDATE_SEARCH.type:
			return Object.assign({}, state, {
				listfilter: _.merge(state.listfilter, action),
				listdata: _.merge(state.listdata, { sentences: null }),
				form: _.merge(state.form, { loading: true })
			});
		case constants.GET_TOKEN_SUCCESS.type:
			return Object.assign({}, state, {
				user: _.merge(state.user, { loggedIn: true, token: action.access_token, refreshToken: action.refresh_token, expires: action.expires_in }),
				form: _.merge(state.form, { loading: false, message: "Login succesvol" })
			});
		case constants.GET_TOKEN_FAILURE.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: "Login niet succesvol" })
			});			
		case constants.REFRESH_TOKEN_SUCCESS.type:
			return Object.assign({}, state, {
				user: _.merge(state.user, { loggedIn: true, token: action.access_token, refreshToken: action.refresh_token, expires: action.expires_in }),
			});
			case constants.GET_SENTENCES_SUCCESS.type:
			return Object.assign({}, state, {
				listdata: action,
				form: _.merge(state.form, { loading: false })
			});
		case constants.GET_SENTENCES_FAILURE.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: action.error ? action.error : 'Kon zinnen niet ophalen' })
			});
		case constants.DELETE_SENTENCE.type:
			return Object.assign({}, state, {
				listdata: _.merge(state.listdata, { sentences: null }),
				form: _.merge(state.form, { loading: true })
			});
		case constants.DELETE_SENTENCE_FAIL.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: "Kon zin niet verwijderen" })
			});
			case constants.DELETE_SENTENCE_SUCCESS.type:
				return Object.assign({}, state, {
					form: _.merge(state.form, { loading: false, message: "Zin is verwijderd" })
				});
		case constants.API_FAILURE.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: "Server storing" })
			});
		case constants.CLEAR_MESSAGE.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { message: null })
			});
		case constants.POST_LOG.type:
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: true })
			});
		case constants.POST_LOG_SUCCESS.type:
			let score = (parseInt(action.sentencecount, 10) - parseInt(action.errorcount, 10)) / parseInt(action.sentencecount, 10) * 100;
			score = Math.round(score);
			return Object.assign({}, state, {
				form: _.merge(state.form, { loading: false, message: "Niveau aangepast" }),
				user: _.merge(state.user, { level: action.userlevel }),
				dashboard: { feedback: action.feedback, score: score }
			});
		case constants.SET_HELP.type:
			return Object.assign({}, state, {
				navbar: _.merge(state.navbar, { help: { title: action.title, text: action.text } })
			});
		case constants.LOGOUT.type:
			return Object.assign({}, state, {
				user: _.merge(state.user, { loggedIn: false, id: -1, level: -1, token: null, refresh_token: null }),
				form: _.merge(state.form, { loading: false, message: null }),
				intro: _.merge(state.intro, { fullImage: null, audio: null }),
				cards: {},
				chosenCards: {}
			});
		default:
			return state;
	}
}

export default userData;
