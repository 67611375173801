import React from 'react';
import _ from 'lodash';
import Spinner from '../spinner';
import TextInput from '../text-input';
import Form from '../form';
import SubmitButton from '../submit-button';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import { ToggleButtonGroup, ToggleButton, Row, Col, Container, FormControl } from 'react-bootstrap';

const SEARCH = 1;
const CATEGORY = 2;
const APPROVED = 3;
const GENDER = 4;
const PAGE = 5;
const CULTURE = 6;
const POOL = 7;
const FOR_YOUTH = 8;
const LANGUAGE = 9;
const VALUE_TYPE_GROUP = 10;
const VALUE_TYPE_MOD = 11;

class SearchForm extends Form {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = { formData: { gender: 0, valueTypeGroup: 0, valueTypeMod: 0, approved: -1, culture: -1, pool: -1, forYouth: -1, language: 'nl' } };
    }

    onChange(e, v) {
        switch (v) {
            case SEARCH:
                this.setState({ formData: _.merge(this.state.formData, { search: e.currentTarget.value }) });
                break;
            case CATEGORY:
                this.setState({ formData: _.merge(this.state.formData, { categories: e.currentTarget.value }) });
                break;
            case GENDER:
                this.setState({ formData: _.merge(this.state.formData, { gender: e }) });
                break;
            case VALUE_TYPE_GROUP:
                this.setState({ formData: _.merge(this.state.formData, { valueTypeGroup: e }) });
                break;
            case VALUE_TYPE_MOD:
                this.setState({ formData: _.merge(this.state.formData, { valueTypeMod: e }) });
                break;
            case APPROVED:
                this.setState({ formData: _.merge(this.state.formData, { approved: e }) });
                break;
            case CULTURE:
                this.setState({ formData: _.merge(this.state.formData, { culture: parseInt(e.currentTarget.value) }) });
                break;
            case POOL:
                this.setState({ formData: _.merge(this.state.formData, { pool: parseInt(e.currentTarget.value) }) });
                break;
            case FOR_YOUTH:
                this.setState({ formData: _.merge(this.state.formData, { forYouth: e }) });
                break;                
            case LANGUAGE:
                this.setState({ formData: _.merge(this.state.formData, { language: e.currentTarget.value }) });
                break;                
        
            default:

        }
    }

    render() {
        const validation = this.validate(this.props.validationRules);
        const colStyle = { maxWidth: 185 };
        const rowStyle = { marginBottom: 15 };

        return (
            <Container fluid>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Gender</Col>
                    <Col xs={4}>
                        <ToggleButtonGroup name="gender" type="radio" value={this.state.formData.gender} onChange={e => this.onChange(e, GENDER)}>
                            <ToggleButton type="radio" id="tbg-btn-gender-both" value={0}>
                                Beide
                            </ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-gender-m" value={1}>
                                m - Man
                            </ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-gender-f" value={2}>
                                f - Vrouw
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Waardetype</Col>
                    <Col xs={4}>
                        <ToggleButtonGroup name="valuetype" type="radio" value={this.state.formData.valueTypeGroup} onChange={e => this.onChange(e, VALUE_TYPE_GROUP)}>
                            <ToggleButton type="radio" id="tbg-btn-valuetypegrp-all" value={0}>Alle</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypegrp-z" value={1}>Z</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypegrp-a" value={2}>A</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypegrp-hh" value={3}>HH</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypegrp-ll" value={4}>LL</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypegrp-other" value={5}>Anders</ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                    <Col xs={4}>
                        <ToggleButtonGroup name="valuetype" type="radio" value={this.state.formData.valueTypeMod} onChange={e => this.onChange(e, VALUE_TYPE_MOD)}>
                            <ToggleButton type="radio" id="tbg-btn-valuetypemod-all" value={0}>Alle</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypemod-z" value={1}>+</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypemod-a" value={2}>-</ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-valuetypemod-hh" value={3}>+-</ToggleButton>
                        </ToggleButtonGroup>
                    </Col>
                </Row>                
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Goedgekeurd</Col>
                    <Col xs={4}>
                        <ToggleButtonGroup name="approved" type="radio" value={this.state.formData.approved} onChange={e => this.onChange(e, APPROVED)}>
                            <ToggleButton type="radio" id="tbg-btn-approved-all" value={-1}>
                                Alles
                            </ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-approved-yes" value={true}>
                                1 - Ja
                            </ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-approved-no" value={false}>
                                0 - Nee
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Geschikt voor jeugd</Col>
                    <Col xs={4}>
                        <ToggleButtonGroup name="for-youth" type="radio" value={this.state.formData.forYouth} onChange={e => this.onChange(e, FOR_YOUTH)}>
                            <ToggleButton type="radio" id="tbg-btn-for-youth-all" value={-1}>
                                Alles
                            </ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-for-youth-yes" value={true}>
                                1 - Ja
                            </ToggleButton>
                            <ToggleButton type="radio" id="tbg-btn-for-youth-no" value={false}>
                                0 - Nee
                            </ToggleButton>
                        </ToggleButtonGroup>                    
                    </Col>
                </Row>                
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Categorie (3,4,5)</Col>
                    <Col xs={4}>
                        <TextInput id="categories" onChange={e => this.onChange(e, CATEGORY)}
                            defaultValue={this.props.filter.categories}
                            placeholder="3,4,5" style={{ maxWidth: "108px", display: "inline" }} />
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Cultuur</Col>
                    <Col xs={4}>
                        <FormControl size="md" as="select" value={this.state.formData.culture} onChange={e => this.onChange(e, CULTURE)}>
                            <option key="all" value={-1}>Alle</option>
                            {(this.props.cultures || []).map(c => <option key={c.id} value={c.id}>{c.id} - {c.name}</option>)}
                        </FormControl>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Pool</Col>
                    <Col xs={4}>
                        <FormControl size="md" as="select" value={this.state.formData.pool} onChange={e => this.onChange(e, POOL)}>
                            <option key="all" value={-1}>Alle</option>
                            {(this.props.pools || []).map(c => <option key={c.id} value={c.id}>{c.id} - {c.name}</option>)}
                        </FormControl>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Taal</Col>
                    <Col xs={4}>
                        <FormControl size="md" as="select" value={this.state.formData.language} onChange={e => this.onChange(e, LANGUAGE)}>
                            <option key="nl" value="nl">nl - Nederlands</option>
                            <option key="en" value="en">en - Engels</option>
                        </FormControl>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col xs={2} style={colStyle}>Zoekterm</Col>
                    <Col xs={4}>
                        <TextInput id="search" onChange={(e) => this.onChange(e, SEARCH)} style={{ marginBottom: "5px" }} />                       
                    </Col>
                </Row>
                <SubmitButton disabled={!validation.passes} loading={this.state.loading} formdata={this.state.formData}
                    style={{ marginTop: "5px" }} onClick={() => { this.props.search(this.state.formData); }}>
                    Filteren
                </SubmitButton>

            </Container>
        );
    }
}


/* Now make its container */
const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.form.loading,
        cultures: state.cultures,
        pools: state.pools,
        filter: state.listfilter ? state.listfilter : []
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        search: (data) => {
            dispatch(actions.updateSearch(data));
        }

    }
}

const SearchFormExtended = connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchForm);



export default SearchFormExtended;
