import React, { Component } from 'react';
import './App.css';
import NavBar from './components/navbar';
import SearchForm from './components/pages/search';
import LoginForm from './components/pages/login';
import SentenceList from './components/containers/pages/sentence-list';
import { Container } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/bootstrap/font-awesome.min.css';

class App extends Component {

  componentDidMount() {
    // seed initial store state from cookies if any exist
    this.props.seed();
  }

  render() {

    if (this.props.loggedIn) {
      return (
        <div className="App">
          <NavBar />
          <Container fluid style={{margin:20}}>
            <h4>Filter</h4>
            <SearchForm />
            <hr/>
            <h4>Resultaten</h4>
            <SentenceList></SentenceList>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="App">
          <NavBar />
          <Container fluid style={{margin:20}}>
            <h4>Login</h4>
            <LoginForm />
          </Container>
        </div>
      );
    }



  }
}

export default App;
