import Toastr from '../toastr';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearMessage: () => {
		dispatch(actions.clearMessage());
    }
  }
}

const ReduxToastr = connect(
  null,
  mapDispatchToProps
)(Toastr);

export default ReduxToastr;
