/* Text input */
import React, { Component } from 'react';

class TextInput extends Component {

	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

		changeValue(event) {
		  if (this.props.onChange){
		  	this.props.onChange(event);
		  }
		}

		componentDidMount() {

			if (this.props.defaultValue){
				const defaultValue = this.props.defaultValue + '';
				const spoofEvent = { currentTarget : { id: this.props.id, value: defaultValue }}
				this.changeValue(spoofEvent);
			}

			if (this.props.takeFocus) this.focus();

		}

		// Set keyboard focus on the input element.
		focus() {
			this.refs.inpRef.focus();
		}

		render() {

			var labelId = 'label_' + this.props.name;

			const defaultValue = this.props.defaultValue ? '' + this.props.defaultValue : '';
		  return (


					  <input name={this.props.name} defaultValue={defaultValue} ref="inpRef" id={this.props.id}
							type={this.props.inputType} placeholder={this.props.placeholder} onChange={this.changeValue}
							onKeyDown={this.props.onKeyDown}
							style={this.props.style}
							value={this.props.value}
							className="form-control" aria-describedby={labelId} disabled={this.props.disabled} />


		  );

		}

    }

TextInput.defaultProps = {
  "inputType" : "text",
  "placeholder": "",
  "label": ""
};

export default TextInput;
