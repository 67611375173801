// form component allows for validation with validatorjs
import { Component } from 'react';
import Validator  from 'validatorjs';
import _ from 'lodash';

class Form extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    // updates form state
    // assumes an id field on the element which maps to the verification rules.
    onChange(e) {
        const key = e.currentTarget.id;
        let obj = {};
        obj[key] = e.currentTarget.value;
        this.setState({formData : _.merge(this.state.formData, obj)});
    }

    // validates form data, using validatorjs
    // https://github.com/skaterdav85/validatorjs
    validate(rules) {
        const validationErrors = {
              required: 'Verplicht veld',
              email: 'Ongeldig e-mail adres',
              numeric: 'Ongeldig nummer'
          };
        // data is an object whose keys are the same keys as the ones in the rules.
        const data = this.state.formData;
        //const messages = { required: 'You forgot to give a :attribute' };
        var validation = new Validator(data, rules, validationErrors);
        return { passes : validation.passes(), errors : validation.errors };
    }

}

export default Form;
