import React, { Component } from 'react';
import _ from 'lodash';
import { Container, Row, Col, FormControl, Pagination } from 'react-bootstrap';
import Spinner from '../spinner';
import { ListGroupItem } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import TextInput from '../text-input';
import Form from '../form';
import SubmitButton from '../submit-button';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

const APPROVED = 1;
const SENTENCE = 2;
const CATEGORY = 3;
const CULTURE = 4;
const POOL = 5;
const FOR_YOUTH = 6;
const DATE_OF_BIRTH = 7;
const EN_TRANSLATION = 8;
const NL_TRANSLATION = 9;

class SentenceRow extends Form {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onTranslateClick = this.onTranslateClick.bind(this);
        this.updateSentence = props.updateSentence.bind(this);
        this.removeSentence = props.removeSentence.bind(this);
        this.state = {
            formData: {
                saveFeedback: false,
                id: props.sentence.id, 
                en: props.sentence.en,
                nl: props.sentence.nl,
                enId: props.sentence.en_id,
                nlId: props.sentence.nl_id,
                translationOfId: props.sentence.translation_of_id,
                approved: props.sentence.approved,
                forYouth: props.sentence.for_youth,
                gender: props.sentence.gender, value_type: props.sentence.value_type,
                value_type_modifier: props.sentence.value_type_modifier,
                pool: props.sentence.pool_id,
                culture: props.sentence.culture_id,
                categories: props.sentence.categories
            }
        };
    }

    onChange(e, v) {
        switch (v) {
            case APPROVED:
                this.setState({ formData: _.merge(this.state.formData, { approved: e.currentTarget.checked }) });
                break;
            case SENTENCE:
                this.setState({ formData: _.merge(this.state.formData, { sentence: e.currentTarget.value }) });
                break;
            case CATEGORY:
                this.setState({ formData: _.merge(this.state.formData, { category: e.currentTarget.value }) });
                break;
            case CULTURE:
                this.setState({ formData: _.merge(this.state.formData, { culture: parseInt(e.currentTarget.value) }) });
                break;
            case POOL:
                this.setState({ formData: _.merge(this.state.formData, { pool: parseInt(e.currentTarget.value) }) });
                break;
            case FOR_YOUTH:
                this.setState({ formData: _.merge(this.state.formData, { forYouth: e.currentTarget.checked }) });
                break;
            case EN_TRANSLATION:
                this.setState({ formData: _.merge(this.state.formData, { en: e.currentTarget.value }) });
                break;
            case NL_TRANSLATION:
                this.setState({ formData: _.merge(this.state.formData, { nl: e.currentTarget.value }) });
                break;
        
                default:
        }
    }

    onTranslateClick() {
        if (this.props.sentence.language === 'nl') {
            console.log('---en');
            this.onChange({ currentTarget: { value: '' }}, EN_TRANSLATION);
        } else {
            console.log('---nl');
            this.onChange({ currentTarget: { value: '' }}, NL_TRANSLATION);
        }
    }

    save() {
        this.updateSentence(this.state.formData, (reload) => {
            if (reload) this.props.reload();
            this.setState({ saveFeedback: true });
        });
    }

    render() {
        const { sentence, language } = this.props.sentence;
        const formattedTimestamp = new Date(this.props.sentence.timestamp).toLocaleString('nl-NL');
        const nlFormattedTimestamp = new Date(this.props.sentence.nl_timestamp).toLocaleString('nl-NL');
        const enFormattedTimestamp = new Date(this.props.sentence.en_timestamp).toLocaleString('nl-NL');
        const nl = this.state.formData.nl;
        const en = this.state.formData.en;
        console.log('nl', nl);
        const validation = this.validate(this.props.validationRules);
        const canTranslate = (language === 'nl' && en === null) || (language === 'en' && nl === null);
        return (
            <Container fluid className={this.state.saveFeedback ? 'save-feedback sentence-container' : 'sentence-container'} 
                onAnimationEnd={() => this.setState({ saveFeedback: false })}>
                <Row>
                    <Col>
                        <TextInput defaultValue={sentence} onChange={(e) => { this.onChange(e, SENTENCE); }} />
                    </Col>
                    <div className="timestamp">{formattedTimestamp}</div>
                </Row>
                {en !== null && <Row className="translation">
                    <Col>
                        <div className="flag">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                            <path fill="#012169" d="M0 0h640v480H0z"/>
                            <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                            <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                            <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                            <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                            </svg>
                        </div>
                        <TextInput defaultValue={en} onChange={(e) => { this.onChange(e, EN_TRANSLATION); }} />
                    </Col>
                    <div className="timestamp">{enFormattedTimestamp}</div>
                </Row>}
                {nl !== null && <Row className="translation">
                    <Col>
                        <div className="flag">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
                            <path fill="#21468b" d="M0 0h640v480H0z"/>
                            <path fill="#fff" d="M0 0h640v320H0z"/>
                            <path fill="#ae1c28" d="M0 0h640v160H0z"/>
                            </svg>
                        </div>                    
                        <TextInput defaultValue={nl} onChange={(e) => { this.onChange(e, NL_TRANSLATION); }} />
                    </Col>
                    <div className="timestamp">{nlFormattedTimestamp}</div>
                </Row>}
                <Row>
                    <Col>
                        <div style={{display: 'flex'}} className="sentence-list-item">
                            <span>Goedgekeurd:</span>
                            <input type="checkbox" 
                                defaultChecked={this.props.sentence.approved}
                                onChange={(e) => { this.onChange(e, APPROVED); }}
                            />
                            <span>Jeugd:</span><input type="checkbox"
                                defaultChecked={this.props.sentence.for_youth}
                                onChange={(e) => { this.onChange(e, FOR_YOUTH); }}
                            />
                            <span style={{minWidth:200, fontWeight:'bold'}}>{this.props.sentence.gender} - {this.props.sentence.value_type} {this.props.sentence.value_type_modifier} - {this.props.sentence.date_of_birth}</span>
                            <span>Cultuur:</span><FormControl size="sm" as="select" value={this.state.formData.culture} onChange={e => this.onChange(e, CULTURE)}>
                                {(this.props.cultures || []).map(c => <option key={c.id} value={c.id}>{c.id} - {c.name}</option>)}
                            </FormControl>
                            <span>Pool:</span><FormControl size="sm" as="select" value={this.state.formData.pool} onChange={e => this.onChange(e, POOL)}>
                                {(this.props.pools || []).map(c => <option key={c.id} value={c.id}>{c.id} - {c.name}</option>)}
                            </FormControl>
                        </div>
                    </Col>
                    <Col>
                        <SubmitButton disabled={!validation.passes} loading={this.state.loading}
                            style={{ float: "right", marginTop: "5px" }} onClick={() => this.save()}>
                            Opslaan
                        </SubmitButton>
                        <SubmitButton disabled={!validation.passes} loading={this.state.loading}
                            className="btn-danger"
                            style={{ float: "right", marginTop: "5px", marginRight: "5px" }} onClick={() => { this.removeSentence(this.props.sentence.id); }}>
                            Verwijderen
                        </SubmitButton>
                        {canTranslate && <SubmitButton disabled={!validation.passes} loading={this.state.loading}
                            className="btn-secondary"
                            style={{ float: "right", marginTop: "5px", marginRight: "5px" }} onClick={this.onTranslateClick}>
                            Vertalen
                        </SubmitButton>}
                    </Col>

                </Row>


            </Container>
        );
    }
}

SentenceRow.defaultProps = {
    sentence: "geen data"
};

/* Now make its container */
const mapStateToProps = (state, ownProps) => {
    return {
        loading: state.form.loading,
        cultures: state.cultures,
        pools: state.pools,        
        data: state.listdata ? state.listdata : [],
        filter: state.listfilter ? state.listfilter : []
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updateSentence: (data, callback) => {
            dispatch(actions.updateSentence(data, callback));
        },
        removeSentence: (id) => {
            dispatch(actions.deleteSentence(id));
        }

    }
}

const SentenceRowExtended = connect(
    mapStateToProps,
    mapDispatchToProps
)(SentenceRow);

class SentenceList extends Component {

    constructor(props) {
        super(props);
        this.onPageChange = this.onPageChange.bind(this);
        this.reload = this.reload.bind(this);
        this.state = { formData: { page: 1 } };
    }

    reload() {
        this.props.search({ page: this.state.formData.page - 1});
    }

    onPageChange(e) {
        if (!e.target.text) return;
        let page = parseFloat(e.target.text);
        if (e.target.text.indexOf('First') !== -1) page = 1;
        else if (e.target.text.indexOf('Previous') !== -1) page = this.state.formData.page - 1;
        else if (e.target.text.indexOf('Next') !== -1) page = this.state.formData.page + 1;
        else if (e.target.text.indexOf('Last') !== -1) page = this.props.data.numPages;
        this.setState({ formData: { page } });
        if (this.state.formData.page !== page) {
            this.props.search({ page: page - 1 });
        }
    }

    renderPagination() {

        let pageNumsPre = [], pageNumsPost = [];
        let p =  this.props.filter.page+1-4;
        let dp = 3;
        while (p > 0) {
            pageNumsPre.push(p);
            p -= dp;
            dp *= 2;
        }

        pageNumsPre = _.reverse(pageNumsPre);
        p = this.props.filter.page+1+4;
        dp = 3;
        while (p <= this.props.data.numPages) {
            pageNumsPost.push(p);
            p += dp;
            dp *= 2;
        }
        const pre = pageNumsPre.length > 0 ? _.last(pageNumsPre)+1 : 1;
        const post = pageNumsPost.length > 0 ? _.first(pageNumsPost) : this.props.data.numPages;
        const pageNumsMid = _.range(
            Math.max(pre, this.props.filter.page+1-4, 1),
            Math.min(post, this.props.filter.page+1+4, this.props.data.numPages)+1
        );
        const pageNums = _.uniq(_.concat(pageNumsPre, pageNumsMid, pageNumsPost));

        return <Pagination>
            <Pagination.First disabled={this.props.filter.page === 0} onClick={this.onPageChange} />
            <Pagination.Prev disabled={this.props.filter.page === 0} onClick={this.onPageChange} />
            {pageNums.map(p => <Pagination.Item key={p} onClick={this.onPageChange} active={p === this.props.filter.page+1}>{p}</Pagination.Item>)}
            <Pagination.Next disabled={this.props.filter.page === this.props.data.numPages-1} onClick={this.onPageChange} />
            <Pagination.Last disabled={this.props.filter.page === this.props.data.numPages-1} onClick={this.onPageChange} />
        </Pagination>;
    }

    render() {
        let spinner = null;
        if (this.props.loading) {
            spinner = <Spinner />;
            return spinner;
        }

        let rows = [];

        const info = <span>pagina: <TextInput
            inputType="number"
            style={{ width: "72px", display: "inline" }}
            defaultValue={this.props.filter.page + 1} id="categories"
            // onChange={this.onPageChange} 
            />
            van {this.props.data.numPages}</span>;
        _.each(this.props.data.sentences, (s) => {
            rows.push(<ListGroupItem key={s.id + "_key"}>
                <SentenceRowExtended sentence={s} reload={this.reload}></SentenceRowExtended>
            </ListGroupItem>
            );
        });

        return (
            <div className="container-fluid">
                <span>{this.props.data.numPages} pagina's</span>
                {this.renderPagination()}
                <ListGroup>
                    {rows}
                </ListGroup>
            </div>
        );
    }
}

export default SentenceList;
