import { connect } from 'react-redux';
import SentenceList from '../../pages/sentence-list';
import actions from '../../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.form.loading,
    data: state.listdata ? state.listdata : [],
    filter: state.listfilter ? state.listfilter : []
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSentences: () => {
      dispatch(actions.getSentences());
    },
    search: (data) => {
      dispatch(actions.updateSearch(data));
    }
  }
}

const SentenceListExtended = connect(
  mapStateToProps,
  mapDispatchToProps
)(SentenceList);

export default SentenceListExtended;
