import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Button from '../submit-button';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClick: () => {
        document.location = "#/login";
        dispatch(actions.logout());
    }
  }
};

const LogoutButton = connect(
    null,
  mapDispatchToProps
)(Button);

export default LogoutButton;
