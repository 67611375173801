import fetch from 'isomorphic-fetch';
import _ from 'lodash';
import actions from '../redux/actions';
import constants from '../redux/constants';
import config from '../config';

const routes = { host: config.api_host, actions: ['/admin/sentences?'] };
const CLIENT_URL = 'https://' + routes.host;

function getHeaders(state) {
    return new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + state.user.token,
        credentials: 'include'
    });
}

export default {

    getToken: (dispatch, obj) => {

        const data = new FormData();
        data.append('client_id', config.client_id);
        data.append('client_secret', config.client_secret);
        data.append('scope', '*');
        data.append('username', 'info@reflection-action.nl');
        data.append('password', obj.passwd);
        data.append('grant_type', 'password');

        return fetch(CLIENT_URL + '/oauth/token', { method: 'POST', body: data })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.getTokenResult(json);
                    if (r) {
                        dispatch(r);
                    }
                }
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
            });

    },

    refreshToken: (dispatch, getState) => {
        const data = new FormData();
        data.append('client_id', config.client_id);
        data.append('client_secret', config.client_secret);
        data.append('refresh_token', getState().user.refreshToken);
        data.append('grant_type', 'refresh_token');

        return fetch(CLIENT_URL + '/oauth/token', { method: 'POST', body: data })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.refreshTokenResult(json);
                    if (r) {
                        dispatch(r);
                    }
                }
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
            });
    },

    getCultures: (dispatch, getState) =>{
        const url = CLIENT_URL + '/admin/cultures';
        return fetch(url, { mode: 'cors', method: 'GET', headers: getHeaders(getState()) })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.getCulturesResult(json);
                    if (r) {
                        dispatch(r);
                    }
                }
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
            });
    },

    getPools: (dispatch, getState) =>{
        const url = CLIENT_URL + '/admin/pools';
        return fetch(url, { mode: 'cors', method: 'GET', headers: getHeaders(getState()) })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.getPoolsResult(json);
                    if (r) {
                        dispatch(r);
                    }
                }
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
            });
    },    

    getSentences: (dispatch, getState) => {

        const filter = getState().listfilter;
        const approved = filter.approved ? 1 : 0;
        const gender = filter.gender;
        const valueTypeGroup = filter.valueTypeGroup;
        const valueTypeMod = filter.valueTypeMod;
        const page = filter.page;
        const categories = filter.categories;
        const culture = filter.culture;
        const pool = filter.pool;
        const forYouth = filter.forYouth ? 1 : 0;
        const language = filter.language ? filter.language : 'nl';
        const search = filter.search;

        let url = CLIENT_URL + '/admin/sentences?lang=' + language;
        if (filter.approved !== -1) url += '&approved=' + approved;
        if (filter.forYouth !== -1) url += '&forYouth=' + forYouth;
        if (!isNaN(valueTypeGroup) && valueTypeGroup !== 0) url += '&valueTypeGroup=' + valueTypeGroup;
        if (!isNaN(valueTypeMod) && valueTypeMod !== 0) url += '&valueTypeMod=' + valueTypeMod;
        if (!isNaN(gender)) url += '&gender=' + gender;
        if (categories) url += '&categories=' + categories;
        if (culture && culture !== -1) url += '&culture=' + culture;
        if (pool && pool !== -1) url += '&pool=' + pool;
        if (!isNaN(page)) url += '&page=' + page;
        if (search) url += '&search=' + search;

        return fetch(url, { mode: 'cors', method: 'GET', headers: getHeaders(getState()) })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.getSentencesResult(json);
                    if (r) {
                        dispatch(r);
                    }
                }
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
            });

    },

    addSentenceTranslation: (dispatch, getState, obj) => {
        const data = { sentence: obj.sentence, lang: obj.lang };

        let url = CLIENT_URL + '/admin/add-sentence-translation/' + obj.id;

        return fetch(url, { mode: 'cors', method: 'POST', headers: getHeaders(getState()), body: JSON.stringify(data) })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.updateSentenceResult(json);
                    if (r) {
                        dispatch(r);
                    }
                    return r !== constants.UPDATE_SENTENCE_FAILURE;;
                }
                return false;
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
                return false;
            });
    },

    updateSentence: (dispatch, getState, obj) => {

        const id = obj.id;
        const approved = obj.approved;
        const forYouth = obj.forYouth;
        const gender = obj.gender;
        const pool = obj.pool;
        const culture = obj.culture;
        const sentence = obj.sentence;
        const categories = obj.categories;

        let url = CLIENT_URL + '/admin/update-sentence/' + id;

        const data = {};
        if (!isNaN(gender)) data.gender = gender;
        if (sentence) data.sentence = sentence;
        if (pool) data.pool = pool;
        if (culture) data.culture = culture;
        data.approved = approved ? 1 : 0;
        data.forYouth = forYouth ? 1 : 0;
        if (categories) data.categories = categories.split(',').map(x => parseInt(x));

        return fetch(url, { mode: 'cors', method: 'PUT', headers: getHeaders(getState()), body: JSON.stringify(data) })
            .then(response => response.json())
            .then(json => {
                if (json) {
                    const r = actions.updateSentenceResult(json);
                    if (r) {
                        dispatch(r);
                    }
                    return true;
                }
                return false;
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
                return false;
            });
    },

    deleteSentence: (dispatch, getState, id) => {

        let url = CLIENT_URL + '/admin/delete-sentence/' + id;
        return fetch(url, { mode: 'cors', method: 'DELETE', headers: getHeaders(getState()) })
            .then(response => {
                dispatch(actions.deleteSentencesResult(id, response));
            })
            .catch(error => {
                dispatch(actions.apiFailure(error));
            });

    }
}
