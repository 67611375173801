import React, { Component } from 'react';
import '../styles/toastr.css';
import '../styles/toastr_adjustments.css';
import { ToastMessageAnimated } from "react-toastr";

class Toastr extends Component {

	constructor(props) {
		super(props);
		this.onRemove = this.onRemove.bind(this);
		this.state = {visible:true};
	}

	componentDidMount() {
		setTimeout(this.onRemove, 3000);
	}

	onRemove() {
		this.setState({visible:false});
		this.props.clearMessage();
	}


    render() {
        return this.state.visible ? (
        		<div id="toast-container" className="toast-bottom-right">
				<ToastMessageAnimated
					type="info"
					title={this.props.title || ''}
					message={this.props.message || ''}
					onRemove={this.onRemove} />
				</div>
        ) : '';
    }
}

export default Toastr;
