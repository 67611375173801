import App from '../../App';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.user.loggedIn
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    seed: () => {
		// dispatch(actions.getToken());
    }
  }
}

const ReduxApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default ReduxApp;
