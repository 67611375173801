import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/containers/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';

/* Redux */
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import userData from './redux/reducers';

/* cookies */
import Cookies from 'cookies-js';
import {getCookiesMiddleware} from 'redux-cookies';

/* Redux store creation */
const loggerMiddleware = createLogger()

const store = createStore(
  userData,
  applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
    // loggerMiddleware, // neat middleware that logs actions
    getCookiesMiddleware(Cookies)
  )
)
// Log the initial state
//console.log("Initial store state", store.getState());

// Every time the state changes, log it (useful for analytics)
// Note that subscribe() returns a function for unregistering the listener
/*const unsubscribe = store.subscribe(() =>
  console.log(store.getState())
);
*/

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
document.getElementById('root'));
serviceWorker.unregister();