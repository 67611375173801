export default {
	REDUX_COOKIES_GET: { type: 'REDUX_COOKIES_GET' },
	REDUX_COOKIES_GET_SUCCESS: { type: 'REDUX_COOKIES_GET_SUCCESS' },
	LOGIN: { type: 'LOGIN' },
	LOGIN_SUCCESS: { type: 'LOGIN_SUCCESS' },
	LOGIN_FAILURE: { type: 'LOGIN_FAILURE' },
	REGISTER: { type: 'REGISTER' },
	REGISTER_FAILURE: { type: 'REGISTER_FAILURE' },
	REGISTER_SUCCESS: { type: 'REGISTER_SUCCESS' },
	API_FAILURE: { type: 'API_FAILURE' },
	GET_PROFILE: { type: 'GET_PROFILE' },
	GET_PROFILE_SUCCESS: { type: 'GET_PROFILE_SUCCESS' },
	GET_PROFILE_FAILURE: { type: 'GET_PROFILE_FAILURE' },
	GET_CARDS: { type: 'GET_CARDS' },
	GET_CARDS_SUCCESS: { type: 'GET_CARDS_SUCCESS' },
	GET_CARDS_FAILURE: { type: 'GET_CARDS_FAILURE' },
	CLEAR_CARDS: { type: 'CLEAR_CARDS' },
	CLEAR_MESSAGE: { type: 'CLEAR_MESSAGE' },
	SET_CHOSEN_CARDS: { type: 'SET_CHOSEN_CARDS' },
	ASSIGNMENT_ERROR: { type: 'ASSIGNMENT_ERROR' },
	POST_LOG: { type: 'POST_LOG' },
	POST_LOG_SUCCESS: { type: 'POST_LOG_SUCCESS' },
	LOGOUT: { type: 'LOGOUT' },
	SET_HELP: { type: 'SET_HELP' },
	NAVIGATE: { type: 'NAVIGATE' },
	GET_TOKEN: { type: 'GET_TOKEN' },
	GET_TOKEN_SUCCESS: { type: 'GET_TOKEN_SUCCESS' },
	GET_TOKEN_FAILURE: { type: 'GET_TOKEN_FAILURE' },
	REFRESH_TOKEN_SUCCESS: { type: 'REFRESH_TOKEN_SUCCESS' },
	GET_SENTENCES_FAILURE: { type: 'GET_SENTENCES_FAILURE' },
	GET_SENTENCES_SUCCESS: { type: 'GET_SENTENCES_SUCCESS' },
	UPDATE_SENTENCE: { type: 'UPDATE_SENTENCE' },
	UPDATE_SENTENCE_SUCCESS: { type: 'UPDATE_SENTENCE_SUCCESS' },
	UPDATE_SENTENCE_FAILURE: { type: 'UPDATE_SENTENCE_FAILURE' },
	UPDATE_FILTER: { type: 'UPDATE_FILTER' },
	UPDATE_SEARCH: { type: 'UPDATE_SEARCH' },
	DELETE_SENTENCE: { type: 'DELETE_SENTENCE' },
	DELETE_SENTENCE_SUCCESS: { type: 'DELETE_SENTENCE_SUCCESS' },
	DELETE_SENTENCE_FAIL: { type: 'DELETE_SENTENCE_FAIL' },
	GET_CULTURES: { type: 'GET_CULTURES' },
	GET_CULTURES_SUCCESS: { type: 'GET_CULTURES_SUCCESS' },
	GET_CULTURES_FAILURE: { type: 'GET_CULTURES_FAILURE' },
	GET_POOLS: { type: 'GET_POOLS' },
	GET_POOLS_SUCCESS: { type: 'GET_POOLS_SUCCESS' },
	GET_POOLS_FAILURE: { type: 'GET_POOLS_FAILURE' },
};
