import React, { Component } from 'react';
import LogoutButton from './containers/logout-button';
import Toastr from './containers/toastr';

class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {hidden: false};
    }

    render() {
        const className=this.props.className;

        let toast = this.props.message ? <Toastr message={this.props.message} /> : '';
        if (this.props.loggedIn === true) {
            return <div className={className}>
                    {this.props.children}
            		{/*Ingelogd als: <b>{this.props.userId}</b> - {level}*/}
            		<LogoutButton>Uitloggen</LogoutButton>
					{toast}
            		</div>;
        }
        return <div>{toast}</div>;
    }
}

export default Notification;
