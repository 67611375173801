import React from 'react';
import Spinner from '../spinner';
import TextInput from '../text-input';
import Form from '../form';
import SubmitButton from '../submit-button';
import { connect } from 'react-redux';
import actions from '../../redux/actions';
import config from '../../config';

class LoginForm extends Form {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {formData: {} };
  }

  render() {
    const validation = this.validate(this.props.validationRules);
    return (
      <div className="container-fluid">
      Wachtwoord: <TextInput inputType="password" id="password" onChange={this.onChange}
          defaultValue={config.password}
          style={{maxWidth:"108px", display:"inline"}}
        />
        <br/>
        <SubmitButton disabled={!validation.passes} loading={this.state.loading}
          style={{marginTop:"5px"}} onClick={() => {this.props.login(this.state.formData);} }>
          Inloggen
        </SubmitButton>

      </div>
    );
  }
}


/* Now make its container */
const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.form.loading,
    filter: state.listfilter ? state.listfilter : []
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: (data) => {
      dispatch(actions.getToken(data.password))
      //dispatch(actions.updateSearch(data));
    }

  }
}

const LoginFormExtended = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);



export default LoginFormExtended;
