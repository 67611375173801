import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Spinner from './spinner';

/* A submit button with loading & confirmed statuses for in forms */
class SubmitButton extends Component {

    constructor() {
        super();
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.onClick) this.props.onClick();
    }

    render() {

        let spinner = null;
        if (this.props.loading) {
            spinner = <Spinner />;
        }

        return (
            <Button style={this.props.style}
                className={this.props.className}
                disabled={this.props.disabled || this.props.loading}
                onClick={this.onClick}
                >
                {this.props.children} {spinner}
            </Button>
        );
    }
}

export default SubmitButton;
